"use client"
import {useEffect, useRef} from "react"
import gsap from "gsap"

interface Vars {
  selector: string;
  callIn: () => void;
  callOut: () => void;
}
interface StickyProps {
  children: React.ReactNode;
  vars: Vars;
 }
 
 const Sticky: React.FC<StickyProps> = ({ children, vars }) => {
  const el = useRef<HTMLDivElement>(null);
 const animation = useRef<gsap.core.Timeline | null>(null);

 const handleMove = (e:any)=>{
   const clientX = e.clientX;
   const clientY = e.clientY;
   const xmove = ((e.target.getBoundingClientRect().width) - (clientX - e.target.getBoundingClientRect().left +1 ))/e.target.getBoundingClientRect().width;
   const ymove = ((e.target.getBoundingClientRect().height) - (clientY - e.target.getBoundingClientRect().top +1 ))/e.target.getBoundingClientRect().height;
   const xRange = -(xmove - .5) * 23;
   const yRange = -(ymove - .5) * 23;
   
  //  console.log(xmove )
   const ctx = gsap.context(() => {
    animation.current = gsap.timeline()
    .to(vars.selector,{
      xPercent:()=> xRange,
      yPercent:()=> yRange,
      duration:1,
      ease: "expo.out"
    })
    vars.callIn && vars.callIn() 
   
  }, el.current?.children);

 }
 const handleLeave=()=>{
  const ctx = gsap.context(() => {
    gsap.killTweensOf(vars.selector)
    animation.current = gsap.timeline()
    .to(vars.selector,{
      xPercent:()=> 0,
      yPercent:()=> 0,
      scale:1,
      duration:.7,
      ease: "expo.out"
    })
    vars.callOut && vars.callOut() 
  }, el.current?.children);
 }
 const handleEnter=()=>{
  const ctx = gsap.context(() => {
    gsap.killTweensOf(vars.selector)
    animation.current = gsap.timeline()
    .to(vars.selector,{
      scale:1.04,
      duration:.7,
      ease: "expo.out"
    })
   
  }, el.current?.children);
 }

//  useEffect(() => {
//   const ctx = gsap.context(() => {
//     animation.current = gsap.timeline()
//     .fromTo("target",{
      
//         xPercent: 0,
      
//     },{
      
//     });
//   }, el.current?.children);
//   return () => ctx.revert();      
// }, []);

return (

  <div ref={el}>
  <div  className="relative cursor-pointer inline-block mobile:hidden" >
   {children}
   <div className="absolute top-[-10%] left-[-13%] left-[-13%] right-[-13%] bottom-[-13%]" onMouseMove={(e)=>handleMove(e)} onMouseLeave={handleLeave} onMouseEnter={handleEnter}></div>
  </div>
  <div className="relative cursor-pointer hidden mobile:flex" >
   {children}
   <div className="absolute top-[-10%] left-[-13%] left-[-13%] right-[-13%] bottom-[-13%]"></div>
  </div>
  
  </div>
  
  
  )
}

export default Sticky;