import React from "react";
import Image from "next/image";

const NavbarHamburgerMenu = ({ isDarkBackground }: any,) => {
  const iconColor = isDarkBackground ? "bg-white-100" : "bg-black";
  const pathColor = isDarkBackground ? "#fff" : "#000";
  const borderColor = isDarkBackground ? "border-[#404040]" : "border-white-100" ;
  return (

    <div className="relative min-w-[1.85em] min-h-[1.85em] flex items-center justify-center">

      <div className={`burger-bg absolute w-full shadow-sm h-full ${isDarkBackground?"bg-black" :"bg-white-100"}  rounded-full border-[.07rem] ${borderColor}`}>

      </div>
    <div
      className={`burger-lines rounded-full flex flex-col justify-center items-center gap-[.18em] w-[1.85em] h-[1.85em] cursor-pointer`}
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="29" height="13" viewBox="0 0 29 13" fill="none" className="relative z-[100] w-[.72em] h-[full]">
        <path d="M27 1.5H2"  strokeWidth="3" strokeLinecap="round" className="straight-line" stroke={pathColor} strokeDasharray="100" strokeDashoffset="75"/>
        <path d="M27 11.5H2" strokeWidth="3" strokeLinecap="round" className="straight-line" stroke={pathColor} strokeDasharray="100" strokeDashoffset="75"/>
      </svg>


      <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none" className="absolute h-[.55rem] mobile:h-[.8rem]">

        <path d="M19.6777 2.32233L2 20" stroke={pathColor} strokeWidth="3" className="close-line" strokeLinecap="round" strokeDasharray="125" strokeDashoffset="125"/>
        <path d="M19.6777 19.6777L2 2" stroke={pathColor} strokeWidth="3" className="close-line" strokeLinecap="round" strokeDasharray="125" strokeDashoffset="125"/>
      </svg>
      {/* <div className={`relative w-[.72em] h-[.1em] rounded-[1rem] ${iconColor}`}>
      </div>
      <div className={`relative w-[.72em] h-[.1em] rounded-[1rem] ${iconColor}`}>
      </div> */}
    </div>
    </div>
  );
};

export default NavbarHamburgerMenu;
