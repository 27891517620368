"use client"
import {useEffect, useRef} from "react"
import gsap from "gsap"

interface SlideUpProps {
  children: React.ReactNode;
  vars: Record<string, unknown>;
 }
 
 const SlideUp: React.FC<SlideUpProps> = ({ children, vars }) => {
  const el = useRef<HTMLDivElement>(null);

  useEffect(()=>{
    const childHeight = (el.current?.children[1] as HTMLElement).offsetHeight;
    var style = getComputedStyle(document.documentElement);
    var baseFontSize = parseFloat(style.fontSize);
    gsap.set(el.current,{
      height:()=> childHeight/ baseFontSize +"rem",
    })
  },[])
  const handleHoverIn= ()=>{
    // console.log(el.current?.children)
  
   gsap.to((el.current?.children || []),{
     yPercent:-100,
     duration:.6,
     ease:"expo.out"
   })
 }
 const handleHoverOut= ()=>{
  gsap.to((el.current?.children || []),{
    yPercent:0,
    duration:.6,
    ease:"expo.out"
  })
}
 

return (
  <div ref={el} className={`relative flex flex-col cursor-pointer h-[1rem]  overflow-hidden`} onMouseEnter={handleHoverIn} onMouseLeave={handleHoverOut}>
   {children}
   {children}
  </div>)
}

export default SlideUp;