"use client";
// import Image from "next/image";
import React, { useState, useEffect, useContext } from "react";
import Link from "next/link";
import NavbarHamburgerMenu from "./NavbarHamburgerMenu";
import MakeSticky from "./Other/MakeSticky";
import Sticky from "./Other/Sticky";
import gsap from "gsap";
import SlideUp from "./Other/slideUpAnimation";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ThemeContext } from "../providers/index";
import { usePathname } from "next/navigation";
gsap.registerPlugin(ScrollTrigger);

export const Navbar = () => {
  const [isDarkBackground, setIsDarkBackground] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const { lenisScroll } = useContext(ThemeContext);

  const pathname = usePathname();

  const handleMenu = () => {
    setIsOpen((pre) => !pre);
  };
  useEffect(() => {
    const HeroContainer = document.querySelector(".hero-Wrapper");
    // console.log(HeroContainer?.classList.contains("bg-black"))
    setIsDarkBackground(HeroContainer?.classList.contains("bg-black") || false);
  }, []);
  useEffect(() => {
    let ctx = gsap.context(() => {
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: ".page-content",
          start: () => "top+=10 top",
          end: () => "bottom top+=12%",
          // markers:true,
          onEnter: () => {
            gsap.to(".logo", {
              scale: 0,
            });
            gsap.to(".logo-type", {
              x: "-1.5em",
            });
          },
          onLeaveBack: () => {
            gsap.to(".logo", {
              scale: 1,
            });
            gsap.to(".logo-type", {
              x: "0",
            });
          },
          onLeave: () => {
            setIsDarkBackground(true);
          },
          onEnterBack: () => {
            setIsDarkBackground(false);
          },
          // scrub:.1,
        },
        ease: "none",
      });
      const footl = gsap.timeline({
        scrollTrigger: {
          trigger: ".page-content",
          start: () => "top top",
          end: () => "bottom top+=12%",
          // markers:true,
          onEnter: () => {
            gsap.to(".logo", {
              scale: 0,

              duration: 0.4,
              ease: "power2.in",
            });
            gsap.to(".logo-type", {
              x: "-1.5em",
              duration: 0.4,
              ease: "power2.in",
            });
          },
          onLeaveBack: () => {
            gsap.to(".logo", {
              scale: 1,
              duration: 0.4,
              ease: "power2.in",
            });
            gsap.to(".logo-type", {
              x: "0",
              duration: 0.4,
            });
          },
          onLeave: () => {
            setIsDarkBackground(true);
          },
          onEnterBack: () => {
            setIsDarkBackground(false);
          },
          // scrub:.1,
        },
        ease: "none",
      });

      if (document.querySelector(".mouse-move-container")) {
        const tl2 = gsap.timeline({
          scrollTrigger: {
            trigger: ".mouse-move-container",
            start: () => "top+=10 top",
            end: () => "bottom top",
            // markers:true,
            onEnter: () => {
              setIsDarkBackground(false);
            },
            onLeaveBack: () => {
              setIsDarkBackground(true);
            },
            // scrub:.1,
          },
          ease: "none",
        });
      }
    }, ".page-wrapper");
    // Clean up
    return () => {
      // window.removeEventListener("scroll", handleScroll);
      ctx.revert();
    };
  }, []);
  function handleScrollToFooter() {
    // Using setTimeout to put the scroll action at the end of the event queue
    setTimeout(() => {
      const footer = document.getElementById("footer");
      console.log(footer)
      if (footer) {
        lenisScroll.lenis.scrollTo("#footer");
        // footer.scrollIntoView({ behavior: "smooth" });
        // lenisScroll.scrollTo(footer)
        // console.log(lenisScroll.lenis.scrollTo)
        setIsOpen(false);
      }
    }, 0);
  }
  useEffect(() => {
    if (isOpen) {
      let ctx = gsap.context(() => {
        gsap.killTweensOf(".dark-overlay, .menu-bg, .menu-links");
        const menuTl = gsap
          .timeline({
            ease: "expo.out",
          })
          .to(".dark-overlay", {
            onStart: () => {
              gsap.set(".drawer-menu", { display: "block" });
            },
            autoAlpha: () => 1,
            duration: 0.5,
          })
          .to(
            ".straight-line",
            {
              strokeDashoffset: 100,
              duration: 0.3,
              stagger: 0.05,
              ease: "power2.out",
            },
            "<"
          )
          .to(".close-line", {
            strokeDashoffset: 250,
            duration: 0.3,
            stagger: 0.05,
            ease: "power2.out",
          })
          .to(
            ".menu-bg",
            {
              width: () => "100%",
              duration: 0.5,
              ease: "expo.out",
            },
            "<-.5"
          )
          .to(
            ".straight-line",
            {
              strokeDashoffset: 100,
              duration: 0.3,
              stagger: 0.09,
              ease: "power2.out",
            },
            "<"
          )
          .to(
            ".close-line",
            {
              strokeDashoffset: 250,
              duration: 0.3,
              stagger: 0.09,
              ease: "power2.out",
            },
            "<.3"
          )
          .to(
            ".menu-links.active",
            {
              autoAlpha: () => 1,
              duration: 1,
              ease: "power3.out",
              // onComplete:()=> {!isOpen && gsap.set(".drawer-menu", {display:"none"})},
            },
            "<+.1"
          )
          .to(
            ".menu-links:not(.active)",
            {
              autoAlpha: () => 0.4,
              duration: 1,
              ease: "power3.out",
              // onComplete:()=> {!isOpen && gsap.set(".drawer-menu", {display:"none"})},
            },
            "<"
          );
      }, ".page-wrapper");
    } else if (!isOpen) {
      gsap.killTweensOf(".dark-overlay, .menu-bg, .menu-links");
      let ctx = gsap.context(() => {
        const menuTl = gsap
          .timeline({
            ease: "expo.out",
          })
          .to(".dark-overlay", {
            // onStart:()=> { gsap.set(".drawer-menu", {display:"block"})},
            autoAlpha: () => 0,
            duration: 0.5,
          })
          .to(
            ".close-line",
            {
              strokeDashoffset: 125,
              duration: 0.3,
              stagger: 0.05,
              ease: "power2.in",
            },
            "<"
          )
          .to(".straight-line", {
            strokeDashoffset: 75,
            duration: 0.3,
            stagger: 0.05,
            ease: "power2.in",
          })
          .to(
            ".menu-bg",
            {
              width: () => "0%",
              duration: 0.5,
              ease: "expo.out",
            },
            "<-.5"
          )
          .to(
            ".menu-links",
            {
              autoAlpha: () => 0,
              duration: 0.5,
              ease: "power3.out",
              onComplete: () => {
                gsap.set(".drawer-menu", { display: "none" });
              },
            },
            "<"
          );
      }, ".page-wrapper");
    }
  }, [isOpen]);

  const hoverIn = () => {
    gsap.to(".early-access p", {
      yPercent: -100,
      duration: 0.6,
      ease: "power3.out",
    });
  };
  const hoverOut = () => {
    gsap.to(".early-access p", {
      yPercent: 0,
      duration: 0.6,
      ease: "power3.out",
    });
  };

  return (
    <nav
      className={`fixed top-0 z-50 flex justify-between items-center lg:px-global px-mobile bg-transparent pt-[1.3rem] pb-[10px] w-full mobile:text-[1.6rem] transition-colors duration-300 ${
        isDarkBackground ? "text-black" : "text-white"
      }`}
    >
      <Link href="/" className={` relative pb-[0.1rem] flex flex-row`}>
        <svg
          className="h-[1.05em] w-auto logo pr-[.5em]"
          xmlns="http://www.w3.org/2000/svg"
          width="42"
          height="42"
          viewBox="0 0 42 42"
          fill="none"
        >
          <path
            d="M0 0H19.5292V19.4705H0V0Z"
            fill={isDarkBackground ? "#fff" : "#000"}
          />
          <path
            d="M0 22.4116H19.5292V41.9997H0V22.4116Z"
            fill={isDarkBackground ? "#fff" : "#000"}
          />
          <path
            d="M22.4704 0H31.4409C37.2398 0 41.9408 4.70103 41.9408 10.4999C41.9408 16.2988 37.2398 20.9999 31.4409 20.9999C37.2398 20.9999 41.9408 25.7009 41.9408 31.4998C41.9408 37.2987 37.2398 41.9997 31.4409 41.9997H22.4704V0Z"
            fill={isDarkBackground ? "#fff" : "#000"}
          />
        </svg>
        <svg
          className="logo-type w-auto h-[1.05em]"
          xmlns="http://www.w3.org/2000/svg"
          width="45"
          height="30"
          viewBox="0 0 45 30"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.66508 6.95975C8.43433 7.09702 9.08683 7.50198 9.62256 8.17462C10.1583 8.84726 10.4262 9.60912 10.4262 10.4602C10.4262 11.1878 10.2338 11.8467 9.84921 12.4369C9.47832 13.0135 8.93572 13.4734 8.22142 13.8165C7.50711 14.146 6.67604 14.3107 5.7282 14.3107H0V0H5.46034C6.43564 0 7.27358 0.164728 7.97415 0.494184C8.67473 0.82364 9.20359 1.26978 9.56074 1.8326C9.9179 2.38169 10.0965 2.99942 10.0965 3.68578C10.0965 4.50942 9.87669 5.19579 9.43711 5.74488C8.99754 6.29397 8.40686 6.69893 7.66508 6.95975ZM2.34898 6.01256H5.25429C6.02354 6.01256 6.62109 5.84097 7.04693 5.49779C7.4865 5.14088 7.70629 4.63297 7.70629 3.97406C7.70629 3.32887 7.4865 2.82783 7.04693 2.47092C6.62109 2.10028 6.02354 1.91496 5.25429 1.91496H2.34898V6.01256ZM5.52215 12.3958C6.31888 12.3958 6.9439 12.2036 7.39721 11.8192C7.85052 11.4349 8.07718 10.8995 8.07718 10.2131C8.07718 9.51303 7.83679 8.95708 7.356 8.54526C6.87522 8.13344 6.23646 7.92753 5.43973 7.92753H2.34898V12.3958H5.52215Z"
            fill={isDarkBackground ? "#fff" : "#000"}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M36.7655 8.19187C36.7655 8.61741 36.7381 9.00178 36.6831 9.34496H28.0084C28.0771 10.251 28.4136 10.9785 29.018 11.5276C29.6224 12.0767 30.3642 12.3512 31.2434 12.3512C32.5071 12.3512 33.4 11.8227 33.922 10.7657H36.4565C36.113 11.809 35.488 12.667 34.5814 13.3396C33.6885 13.9985 32.5758 14.328 31.2434 14.328C30.1582 14.328 29.1829 14.0877 28.3175 13.6073C27.4658 13.1131 26.7927 12.4267 26.2982 11.5482C25.8174 10.6559 25.577 9.62637 25.577 8.45955C25.577 7.29273 25.8105 6.27004 26.2776 5.39149C26.7583 4.49922 27.4246 3.81285 28.2762 3.3324C29.1417 2.85194 30.1307 2.61171 31.2434 2.61171C32.3148 2.61171 33.2695 2.84508 34.1075 3.31181C34.9454 3.77853 35.5979 4.43745 36.065 5.28854C36.532 6.12591 36.7655 7.09368 36.7655 8.19187ZM34.3135 7.45059C34.2998 6.58577 33.9907 5.89254 33.3863 5.3709C32.7819 4.84926 32.0332 4.58845 31.1403 4.58845C30.3299 4.58845 29.6362 4.84926 29.0592 5.3709C28.4823 5.87881 28.1389 6.57204 28.029 7.45059H34.3135Z"
            fill={isDarkBackground ? "#fff" : "#000"}
          />
          <path
            d="M15.1236 13.7544C15.8241 14.1252 16.8734 14.3137 18.0284 14.3106C19.1677 14.3076 19.892 14.3 20.8921 13.7544C21.7188 13.3033 22.2131 12.9156 22.6944 12.1061C23.2407 11.1874 23.2407 9.46901 23.2407 9.46901V2.60508L20.8921 2.60508V9.11876C20.8921 10.1352 20.638 10.9112 20.1298 11.4469C19.6079 11.9963 18.9074 12.271 18.0284 12.271C17.1494 12.271 16.4558 11.9963 15.9477 11.4469C15.4395 10.9112 15.1854 10.1352 15.1854 9.11876V2.60508L12.8574 2.60508L12.8574 9.46901C12.8574 10.4991 13.0566 11.3782 13.4549 12.1061C13.8532 12.8341 14.4094 13.3835 15.1236 13.7544Z"
            fill={isDarkBackground ? "#fff" : "#000"}
          />
          <path
            d="M18.0696 18.2777C19.2247 18.2746 20.274 18.4631 20.9745 18.8339C21.6887 19.2048 22.2449 19.7542 22.6432 20.4821C23.0415 21.2101 23.2407 22.0891 23.2407 23.1193V29.9832H20.9127V23.4695C20.9127 22.4531 20.6586 21.6771 20.1504 21.1414C19.6422 20.592 18.9487 20.3173 18.0696 20.3173C17.1906 20.3173 16.4902 20.592 15.9683 21.1414C15.4601 21.6771 15.206 22.4531 15.206 23.4695V29.9832H12.8574V23.1193C12.8574 23.1193 12.8574 21.4009 13.4037 20.4821C13.8849 19.6727 14.3793 19.285 15.206 18.8339C16.206 18.2883 16.9304 18.2807 18.0696 18.2777Z"
            fill={isDarkBackground ? "#fff" : "#000"}
          />
          <path
            d="M28.585 20.1113C28.9284 19.5344 29.3816 19.088 29.9447 18.7721C30.5216 18.4425 31.2014 18.2777 31.9843 18.2777V20.7088H31.3868C30.4666 20.7088 29.7662 20.9423 29.2855 21.4093C28.8185 21.8763 28.585 22.6866 28.585 23.8404V29.9832H26.2364V18.295H28.585V20.1113Z"
            fill={isDarkBackground ? "#fff" : "#000"}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M36.1621 29.2795C37.0273 29.7602 37.9956 30.0006 39.0669 30.0006C40.1519 30.0006 41.1408 29.7602 42.0336 29.2795C42.94 28.785 43.6542 28.0914 44.1761 27.1986C44.698 26.3058 44.959 25.2825 44.959 24.1288C44.959 22.9751 44.7049 21.9518 44.1967 21.059C43.6885 20.1662 42.995 19.4795 42.116 18.9988C41.237 18.518 40.2549 18.2777 39.1699 18.2777C38.0849 18.2777 37.1029 18.518 36.2239 18.9988C35.3449 19.4795 34.6513 20.1662 34.1431 21.059C33.6349 21.9518 33.3809 22.9751 33.3809 24.1288C33.3809 25.2963 33.6281 26.3264 34.1225 27.2192C34.617 28.0982 35.2968 28.785 36.1621 29.2795ZM40.7562 27.5282C40.2343 27.8167 39.6712 27.9609 39.0669 27.9609C38.1192 27.9609 37.3295 27.6312 36.6977 26.972C36.0797 26.2989 35.7706 25.3512 35.7706 24.1288C35.7706 23.3047 35.9217 22.6111 36.2239 22.0479C36.526 21.4711 36.9312 21.0384 37.4394 20.75C37.9613 20.4615 38.5244 20.3173 39.1287 20.3173C39.733 20.3173 40.2961 20.4615 40.8181 20.75C41.34 21.0384 41.7589 21.4711 42.0748 22.0479C42.3906 22.6111 42.5486 23.3047 42.5486 24.1288C42.5486 24.9529 42.3838 25.6534 42.0542 26.2303C41.7245 26.8071 41.2919 27.2398 40.7562 27.5282Z"
            fill={isDarkBackground ? "#fff" : "#000"}
          />
        </svg>
      </Link>

      <div className="relative flex z-[10]">
        <div             onClick={handleScrollToFooter}
        >
        <Sticky
          vars={{
            selector: ".early-access",
            callIn: hoverIn,
            callOut: hoverOut,
          }}
        >
          <div
            className={`early-access ${
              isDarkBackground ? "bg-white-100" : "bg-black"
            } mobile:hidden flex items-center ${
              isDarkBackground ? "text-black" : "text-white-100"
            } ${
              isDarkBackground ? "shadow-sm" : ""
            } rounded-[10rem] px-[.8rem] py-[.45rem] text-[.5rem] mr-[.8rem] cursor-pointer`}
          >
            {/*
        <SlideUp vars={{"height":".7rem"}}>
       <p className=""> Early Access </p>
        </SlideUp> */}
            <div className="relative flex flex-col cursor-pointer h-[.75rem]  overflow-hidden">
              <p>Early Access </p>
              <p>Early Access</p>
            </div>
          </div>
        </Sticky>
        </div>
        <div onClick={handleMenu}>
          <MakeSticky vars={{ move: 20 }}>
            <NavbarHamburgerMenu
              setOpen={setIsOpen}
              isDarkBackground={isDarkBackground}
            />
          </MakeSticky>
        </div>
      </div>
      <div
        className="drawer-menu fixed w-full h-screen top-0 left-0 z-1"
        onClick={() => setIsOpen(false)}
      >
        <div
          className="dark-overlay opacity-0 absolute w-full h-screen bg-[#0000006b]"
          onClick={() => setIsOpen(false)}
        ></div>
        <div className="menu-contain absolute right-0 h-full w-[20rem] tablet:w-full">
          <div className="menu-bg absolute right-0 bg-white-100 w-0 h-full flex items-center justify-center">
            <div className="flex flex-col p-[2rem] gap-[2rem] text-[1.7rem] tablet:text-center">
              <Link
                href="/"
                className={` ${
                  pathname === "/" ? "active" : ""
                } menu-links opacity-0`}
              >
                <SlideUp vars={{ height: "2rem" }}>
                  <p>Home</p>
                </SlideUp>
              </Link>

              <Link
                href="https://ibiza.buenro.com/"
                className="menu-links opacity-0"
              >
                <SlideUp vars={{ height: "2rem" }}>
                  <p>Ibiza</p>
                </SlideUp>
              </Link>

              {/* <Link href="/coming-soon" className="menu-links opacity-0">
               <SlideUp vars={{"height":"2rem"}}>
               <p className="w-[12rem]">Coming Soon</p>
               </SlideUp>
              </Link> */}

              <Link
                href="/about"
                className={` ${
                  pathname === "/about" ? "active" : ""
                } menu-links opacity-0`}
              >
                <SlideUp vars={{ height: "2rem" }}>
                  <p>About</p>
                </SlideUp>
              </Link>

              <div
                className="menu-links opacity-0"
                onClick={handleScrollToFooter}
              >
                <SlideUp vars={{ height: "2rem" }}>
                  <p className="w-[10.5rem]">Join Waitlist</p>
                </SlideUp>
              </div>

              <Link
                href="https://buenro.super.site/"
                target="_blank"
                className={` ${
                  pathname === "/careers" ? "active" : ""
                } menu-links opacity-0`}
              >
                <SlideUp vars={{ height: "2rem" }}>
                  <p>Careers</p>
                </SlideUp>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};