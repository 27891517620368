"use client"
import {useEffect, useRef} from "react"
import gsap from "gsap"

interface MakeSickyProps {
  children: React.ReactNode;
  vars: Record<string, unknown>;
 }
 
 const MakeSicky: React.FC<MakeSickyProps> = ({ children, vars=[] }) => {
  const el = useRef<HTMLDivElement>(null);
 const animation = useRef<gsap.core.Timeline | null>(null);

 const handleMove = (e:any)=>{
   const clientX = e.clientX;
   const clientY = e.clientY;
   const xmove = ((e.target.getBoundingClientRect().width) - (clientX - e.target.getBoundingClientRect().left +1 ))/e.target.getBoundingClientRect().width;
   const ymove = ((e.target.getBoundingClientRect().height) - (clientY - e.target.getBoundingClientRect().top +1 ))/e.target.getBoundingClientRect().height;
   const xRange = -(xmove - .5) * 23;
   const yRange = -(ymove - .5) * 23;
   
  //  console.log(xmove )
   const ctx = gsap.context(() => {
    animation.current = gsap.timeline()
    .to(".burger-bg",{
      xPercent:()=> xRange,
      yPercent:()=> yRange,
      duration:1,
      ease: "expo.out"
    })
    .to(".burger-lines",{
      xPercent:()=> xRange * 1.6,
      yPercent:()=> yRange * 1.6,
      duration:1,
      ease: "expo.out"
    },"<");
  }, el.current?.children);

 }
 const handleLeave=()=>{
  const ctx = gsap.context(() => {
    gsap.killTweensOf(".burger-bg, .burger-lines")
    animation.current = gsap.timeline()
    .to(".burger-bg",{
      xPercent:()=> 0,
      yPercent:()=> 0,
      scale:1,
      duration:.7,
      ease: "expo.out"
    })
    .to(".burger-lines",{
      xPercent:()=> 0,
      yPercent:()=> 0,
      scale:1,
      duration:.7,
      ease: "expo.out"
    },"<")
  }, el.current?.children);
 }
 const handleEnter=()=>{
  const ctx = gsap.context(() => {
    gsap.killTweensOf(".burger-bg, .burger-lines")
    animation.current = gsap.timeline()
    .to(".burger-bg",{
      scale:1.4,
      duration:.7,
      ease: "expo.out"
    })
    .to(".burger-lines",{
      scale:1.1,
      duration:.7,
      ease: "expo.out"
    },"<")
  }, el.current?.children);
 }

//  useEffect(() => {
//   const ctx = gsap.context(() => {
//     animation.current = gsap.timeline()
//     .fromTo("target",{
      
//         xPercent: 0,
      
//     },{
      
//     });
//   }, el.current?.children);
//   return () => ctx.revert();      
// }, []);

return (

  <div ref={el}>
  <div  className="relative cursor-pointer mobile:hidden" >
   {children}
   <div className="absolute top-[-10%] left-[-13%] left-[-13%] right-[-13%] bottom-[-13%]" onMouseMove={(e)=>handleMove(e)} onMouseLeave={handleLeave} onMouseEnter={handleEnter}></div>
  </div>
  <div className="relative cursor-pointer hidden mobile:flex" >
   {children}
   <div className="absolute top-[-10%] left-[-13%] left-[-13%] right-[-13%] bottom-[-13%]"></div>
  </div>
  
  </div>
  
  
  )
}

export default MakeSicky;